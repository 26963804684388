<template>
    <div class="wrapper">
        <CheckStatusDescription />
        <p class="mt-40">{{ $t('bankAccount.yourOrderNumberIs') }}</p>
        <h2>{{ orderNumber }}</h2>
        <p class="mt-40">
            {{ $t('bankAccount.whenMakingBankTransfer') }} <span>{{ $t('bankAccount.whenWeReceivePayment') }}</span>
        </p>
        <p class="mt-40">{{ $t('bankAccount.paymentInformation') }}:</p>
        <p>{{ $t('bankAccount.accountIsHeldAt') }} - <span>Paysera</span></p>
        <p>{{ $t('bankAccount.payseraAccountOwner') }} - <span>UAB "Londesa"</span></p>
        <p>{{ $t('bankAccount.accountNumber') }}</p>
        <h5>LT91 3500 0100 0267 7341</h5>
        <p class="mt-40">
            {{ $t('bankAccount.amountToBePaid') }} - <span>{{ amountToPay }}Eur</span>
        </p>
    </div>
</template>

<script>
    import CheckStatusDescription from '@/components/Order/ModalContent/CheckStatusDescription.vue';

    export default {
        name: 'BankAccountModalContent',
        components: { CheckStatusDescription },
        props: {
            orderNumber: {
                type: Number,
                default: 0,
            },
            amountToPay: {
                type: String,
                default: '0',
            },
        },
    };
</script>

<style lang="scss" scoped>
    .wrapper {
        text-align: center;
        max-width: 320px;

        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        .btn {
            display: inline;

            font-size: $font-16;
        }

        p span {
            font-weight: 700;
        }
    }
</style>
