<template>
    <div class="order-section__wrapper">
        <h4>{{ $t('common.yourOrder') }}</h4>
        <div class="title-section">
            <CRButton @click="$emit('openModal')" pattern="thin">
                <h5>{{ $t('navbar.individualOrder') }}</h5>
            </CRButton>
            <h5 class="price">
                <span>€{{ individualOrder.prices.price_without_discount }}</span>
            </h5>
        </div>
        <div class="price-section">
            <TitlePrice title="Price" :price="individualOrder.prices.price_without_discount" />
            <TitlePrice
                v-if="appliedDiscountPoints"
                class="title-price"
                :title="$t('common.discount')"
                :price="appliedDiscountPoints.discountWithPoints"
                :description="$t('common.discountCodesAndPoints')"
                is-discount
            />
            <TitlePrice
                v-if="individualOrder.prices.discount_amount != 0"
                class="title-price"
                :title="$t('common.discount')"
                :price="individualOrder.prices.discount_amount"
                is-discount
            />
            <TitlePrice
                v-if="individualOrder.prices.discount_from_codes != 0"
                class="title-price"
                :title="$t('common.discount')"
                :price="individualOrder.prices.discount_from_codes"
                :description="$t('common.discountCodesAndPoints')"
                is-discount
            />
            <TitlePrice :title="$t('common.delivery')" :price="individualOrder.prices.delivery_price" />
        </div>
        <div class="total-section">
            <TitlePrice
                class="total-price"
                :title="$t('common.total')"
                :price="totalPrice"
                :description="$t('common.vatAmount', { vat: vatPrice })"
            />
        </div>
    </div>
</template>

<script>
    import TitlePrice from '@/components/Cart/TitlePrice.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { mapGetters, mapMutations } from 'vuex';
    import { VATApi } from '@/api';
    import { calcVat } from '@/helpers/CartHelper';

    export default {
        name: 'IndividualPriceSection',
        components: { CRButton, TitlePrice },
        props: {
            individualOrder: {
                type: Object,
                default: () => {},
            },
        },
        emits: ['openModal'],
        data() {
            return {
                vatData: [],
            };
        },
        computed: {
            ...mapGetters('discountPoint', ['appliedDiscountPoints']),

            totalPrice() {
                if (this.appliedDiscountPoints) {
                    return (
                        Number(this.individualOrder.prices.total_price) -
                        Number(this.appliedDiscountPoints.discountWithPoints)
                    ).toFixed(2);
                }

                return this.individualOrder.prices.total_price;
            },

            vatPrice() {
                const totalPriceWithOutDelivery = this.appliedDiscountPoints
                    ? Number(this.individualOrder.prices.total_price_with_out_delivery) -
                      Number(this.appliedDiscountPoints.discountWithPoints)
                    : this.individualOrder.prices.total_price_with_out_delivery;

                const { vat } = calcVat(
                    this.vatData,
                    totalPriceWithOutDelivery,
                    this.individualOrder.prices.delivery_price
                );

                return vat;
            },
        },

        methods: {
            ...mapMutations('orderPrices', ['setPrices']),

            async getVAT() {
                try {
                    const response = await VATApi.getAll();
                    this.vatData = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },

        async mounted() {
            this.setPrices(this.individualOrder.prices);
            await this.getVAT();
        },
    };
</script>

<style lang="scss" scoped>
    .order-section__wrapper {
        width: 100%;
        padding: 15px;

        background: $primary-500;

        border-radius: $br-20;

        h4 {
            margin-bottom: 16px;
        }

        .title-section {
            @include row-align-center-justify-between;

            padding-bottom: 25px;

            border-bottom: 1px solid $grey-line;
        }

        .price-section {
            border-bottom: 1px solid $grey-line;

            padding: 25px 0;

            .title-price:last-child {
                margin-bottom: 0;
            }
        }

        .total-section {
            padding-top: 25px;

            .total-price {
                margin-bottom: 0;

                &:deep(p) {
                    width: 100%;
                    text-align: end;
                }

                &:deep(.price span) {
                    font-size: $font-30;
                }
            }
        }
    }
</style>
