<template>
    <div class="payment-page">
        <div class="wrapper">
            <DiscountPointSection class="mb-32" :price="prices?.price_without_discount" />
            <Payment
                :check-out="currentCheckOut"
                :is-loading="isLoading"
                :is-success-modal-opened="isSuccessModalOpened"
                @completeOrder="makePayment"
                @setCheckOut="setCheckOut"
                @changeSuccessModalValue="changeSuccessModalValue"
            />
        </div>
        <IndividualPriceSection
            v-if="currentCheckOut"
            class="order-section"
            :individual-order="currentCheckOut"
            @openModal="isIndividualOrderModalOpened = true"
        />
        <IndividualOrderModal
            v-if="isIndividualOrderModalOpened"
            :checkOut="currentCheckOut"
            @close="isIndividualOrderModalOpened = false"
        />
    </div>
</template>

<script>
    import DiscountPointSection from '@/components/Cart/DiscountPointSection.vue';
    import IndividualOrderModal from '@/components/Modals/IndividualOrderModal.vue';
    import Payment from '@/components/Order/Payment.vue';
    import IndividualPriceSection from '@/components/Order/Individual/IndividualPriceSection.vue';
    import { PaymentTypeEnum } from '@/utils/enums';
    import { IndividualOrderApi } from '@/api';
    import { mapGetters, mapMutations } from 'vuex';
    import { TYPE } from 'vue-toastification';
    import { ConversionEventNameEnum, GoogleAnalyticsEnum } from '@/utils/enums';

    export default {
        name: 'PaymentPage',
        components: { IndividualPriceSection, Payment, IndividualOrderModal, DiscountPointSection },
        computed: {
            ...mapGetters('orderManagement', ['currentCheckOut']),
            ...mapGetters('checkOut', ['paymentType', 'paymentData']),
            ...mapGetters('orderPrices', ['prices']),
            ...mapGetters('discountPoint', ['appliedDiscountPoints']),
        },
        data() {
            return {
                isIndividualOrderModalOpened: false,
                isSuccessModalOpened: false,
                isLoading: false,
            };
        },
        methods: {
            ...mapMutations('orderManagement', ['setCurrentCheckOut']),

            changeSuccessModalValue(val) {
                this.isSuccessModalOpened = val;
            },

            setCheckOut(checkOut) {
                if (checkOut) this.setCurrentCheckOut(checkOut);
            },

            async makePayment() {
                try {
                    this.isLoading = true;

                    const data = {
                        checkOutId: this.currentCheckOut.id,
                        paymentableType: this.paymentType,
                        paymentable_data: this.paymentData,
                        discountPoints: this.appliedDiscountPoints,
                    };

                    const response = await IndividualOrderApi.payment(data);

                    if (this.paymentType === PaymentTypeEnum.MONTONIO) {
                        window.location.href = response.url;
                    } else {
                        this.setCurrentCheckOut(response.checkOut);
                        this.isSuccessModalOpened = true;
                        this.$filters.toast(this.$t('orderManagement.statuses.new'), { type: TYPE.SUCCESS });
                    }

                    this.$gtag.event(GoogleAnalyticsEnum.PURCHASE, {
                        currency: 'EUR',
                        value: this.currentCheckOut.prices.total_price,
                    });

                    fbq('track', ConversionEventNameEnum.PURCHASE, {
                        currency: 'EUR',
                        value: this.currentCheckOut.prices.total_price,
                    });
                    
                } catch (error) {
                    this.$filters.toast(error.message);
                } finally {
                    this.isLoading = false;
                }
            },
        },

        beforeRouteLeave() {
            this.setCurrentCheckOut(null);
        },
    };
</script>

<style lang="scss" scoped>
    .payment-page {
        padding-top: 25px;
    }

    .wrapper {
        margin-bottom: 20px;
    }

    @include media($md) {
        .payment-page {
            @include row-align-start-justify-between;
            flex-wrap: wrap;

            .wrapper {
                margin-bottom: 0;
            }

            .wrapper,
            .order-section {
                width: 49%;
            }
        }
    }

    @include media($lg) {
        .payment-page {
            padding-top: 50px;
        }
    }

    @include media($xl) {
        .payment-page {
            .wrapper,
            .order-section {
                width: 48%;
            }
        }
    }

    @include media($xxl) {
        .payment-page {
            .wrapper,
            .order-section {
                width: 45%;
            }
        }
    }
</style>
