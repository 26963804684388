<template>
    <div>
        <div class="payment-item" v-if="!isCheckOutFree">
            <h4>{{ $t('common.payment') }}</h4>
            <div v-for="method in paymentMethods" :key="method.id" class="radio__wrapper">
                <CRRadio @change="setPaymentType" :modelValue="paymentType" :value="method.type">
                    {{ $t(`paymentMethods.${method.type}`) }}
                </CRRadio>
                <div v-if="paymentType === PaymentTypeEnum.MONTONIO">
                    <MontonioContent @selected="selectedMontionioPaymentMethod" v-if="method.type === PaymentTypeEnum.MONTONIO"/>
                </div>
            </div>
        </div>
        <div class="payment-terms">
            <CRCheckbox class="checkbox" v-model:checked="isDiscountPolicyChecked">
                {{ $t('common.iAgreeWithDiscount') }}
            </CRCheckbox>
            <CRCheckbox class="checkbox" v-model:checked="isContactPolicyChecked">
                {{ $t('common.iAgreeWithContacts') }}
            </CRCheckbox>
            <p v-if="showErrors && !isContactPolicyChecked" class="error">{{ $t('errros.i_agreen_with_contact_information')}}</p>
            <CRCheckbox class="checkbox" v-model:checked="isPolicyChecked">
                {{ $t('common.iAgreeWith') }}
                <router-link class="terms-link" :to="{ name: 'termsAndConditions' }" target="_blank">
                    {{ $t('common.termsAndConditionsLowest') }}
                </router-link>
            </CRCheckbox>
            <p v-if="showErrors && !isPolicyChecked" class="error">{{ $t('errors.i_agreen_with_term_of_service')}}</p>
        </div>

    
        <div class="payment-info mt-20" v-if="user === null">
            <div class="bold">{{ $t('checkout.info_text.1') }}</div>
            <ul>
                <li>{{ $t('checkout.info_text.2') }}</li>
                <li>{{ $t('checkout.info_text.3') }}</li>
                <li>{{ $t('checkout.info_text.4') }}</li>
            </ul>
            <div>{{ $t('checkout.info_text.5') }}</div>
        </div>

        <div class="btn-section">
            <CRButton class="mb-10 btn" @click="$router.push({ name: 'checkOutDelivery' })" pattern="outline">
                {{ $t('buttons.back') }}
            </CRButton>

            <div class="mb-10 pay-btn__wrapper">
                <CRButton
                    v-if="user === null"
                    class="mb-10 btn"
                    @click="payWithRegistration()"
                >
                    {{ $t('buttons.registerOrLogin') }}
                </CRButton>

                <CRButton
                    class="mb-10 btn"
                    @click="placeOrder()"
                    :loading="isLoading"
                >
                    <span v-if="user !== null">{{ $t('buttons.payCompleteOrder') }}</span>
                    <span v-else>{{ $t('buttons.payWithoutRegistration') }}</span>
                </CRButton>
            </div>
        </div>

        <SuccessModal @close="closeSuccessModal" v-if="isSuccessModalOpened" :title="$t('common.payment')">
            <MontonioModalContent v-if="paymentType === PaymentTypeEnum.MONTONIO" />
            <CashModalContent v-if="paymentType === PaymentTypeEnum.CASH" :is-check-out-free="isCheckOutFree" />
            <BankAccountModalContent
                v-if="paymentType === PaymentTypeEnum.BANK_ACCOUNT"
                :amount-to-pay="checkOut.prices.total_price"
                :order-number="checkOut.orderNumber"
            />
        </SuccessModal>

        <PreloaderModal v-if="isPreloaderModalOpened" :title="$t('common.paymentProcessing')" />
    </div>
</template>

<script>
    import PreloaderModal from '@/components/Modals/PreloaderModal';
    import SuccessModal from '@/components/Modals/SuccessModal';
    import CRRadio from '@/components/Common/Radio/CRRadio.vue';
    import CRCheckbox from '@/components/Common/Checkbox/CRCheckbox.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import MontonioModalContent from '@/components/Order/ModalContent/MontonioModalContent.vue';
    import CashModalContent from '@/components/Order/ModalContent/CashModalContent.vue';
    import BankAccountModalContent from '@/components/Order/ModalContent/BankAccountModalContent.vue';
    import { PaymentMethodApi, PaymentApi } from '@/api';
    import { PaymentTypeEnum, CheckOutStatusEnum } from '@/utils/enums';
    import { mapActions, mapGetters } from 'vuex';
    import { TYPE } from 'vue-toastification';
    import MontonioContent from './Payment/MontonioContent.vue';

    export default {
        name: 'Payment',
        components: {
            CRRadio,
            CRCheckbox,
            CRButton,
            SuccessModal,
            MontonioModalContent,
            CashModalContent,
            BankAccountModalContent,
            PreloaderModal,
            MontonioContent,
        },
        props: {
            checkOut: {
                type: Object,
                default: () => {},
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
            isSuccessModalOpened: {
                type: Boolean,
                default: false,
            },
            isCheckOutFree: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['changeSuccessModalValue', 'completeOrder', 'setCheckOut'],
        data() {
            return {
                PaymentTypeEnum,
                showErrors: false,
                isPolicyChecked: false,
                isContactPolicyChecked: false,
                isDiscountPolicyChecked: false,
                isPreloaderModalOpened: false,

                paymentToken: null,

                paymentMethods: [],
            };
        },
        watch: {
            $route: {
                async handler(value) {
                    this.paymentToken = value.query['order-token'];

                    if (this.paymentToken && this.paymentType === PaymentTypeEnum.MONTONIO) {
                        await this.confirmPaymentCallback();
                    }
                },
                immediate: true,
            },
            checkOut: {
                async handler(checkOut) {
                    if (checkOut?.payment) {
                        this.$emit('changeSuccessModalValue', true);
                    }

                    if (!checkOut) {
                        this.setCheckOut();
                    }
                },
                immediate: true,
            },
        },
        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('checkOut', ['paymentType', 'paymentData']),
            
            isMontonioAndPaymentMethodIsSelected() {
                if(this.paymentType === PaymentTypeEnum.MONTONIO) {
                    if(this.paymentData.provider === 'paymentInitiation' && this.paymentData.payment !== null) {
                        return true;
                    } else {
                        if (this.paymentData.provider !== null) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                } else {
                    return true;
                }
            },
        },

        methods: {
            ...mapActions('checkOut', ['setPaymentType', 'setCheckOut', 'setContinueCheckout']),
            ...mapActions('cart', ['clearCartCookies',]),
            ...mapActions('discountPoint', ['clearDiscountPoints']),

            placeOrder() {
                if(!this.isPolicyChecked || !this.isContactPolicyChecked || !this.isMontonioAndPaymentMethodIsSelected) {
                    this.showErrors = true;
                } else {
                    this.$emit('completeOrder')
                }
            },
            payWithRegistration() {
                this.setContinueCheckout(true);

                this.$router.push({
                    name: 'login',
                })
            },

            selectedMontionioPaymentMethod(data) {
                console.log(data);
            },

            async getPaymentMethods() {
                try {
                    const response = await PaymentMethodApi.getAll();
                    this.paymentMethods = response.data.filter((method) => method.isActive);
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            closeSuccessModal() {
                if (this.checkOut.payment) {
                    this.clearCartCookies();

                    this.$router.push({ name: 'main' });
                }

                this.$emit('changeSuccessModalValue', false);
            },

            async confirmPaymentCallback() {
                this.isPreloaderModalOpened = true;

                try {
                    const response = await PaymentApi.montonioWebhook({ paymentToken: this.paymentToken });

                    if (response.status === CheckOutStatusEnum.PAID) {
                        this.$emit('changeSuccessModalValue', true);
                        this.$filters.toast(this.$t('orderManagement.statuses.paid'), { type: TYPE.SUCCESS });
                        this.$emit('setCheckOut', response.checkOut);
                    } else {
                        this.$filters.toast(this.$t('orderManagement.statuses.' + response.status), { type: TYPE.ERROR });
                    }

                    this.clearDiscountPoints();
                } catch (error) {
                    this.$filters.toast(error.message);
                } finally {
                    this.isPreloaderModalOpened = false;
                    // this.$router.replace({ query: null });
                    // this.paymentToken = null;
                }
            },
        },
        async mounted() {
            await this.getPaymentMethods();
            this.setPaymentType();

            // if(this.$route.query['order-token'] != undefined) {
            //     this.paymentToken = this.$route.query['order-token'];
            //     await this.confirmPaymentCallback();
            // }
        },
    };
</script>

<style lang="scss" scoped>
    .bold {
        font-weight: bold;
    }

    .error {
        font-size: 13px;
        color: red;
    }

    .payment-terms {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        gap: 10px;
    }

    
    .payment-item {
        h4 {
            margin-bottom: 18px;
        }

        .radio__wrapper {
            margin-bottom: 16px;

            p {
                max-width: 320px;
                margin-top: 10px;
                font-size: $font-14;
                font-style: italic;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .payment-info {
        ul {
            margin-top: 10px;
            margin-bottom: 10px;
            margin-left: 15px;
            list-style-type: circle;
        }
    }

    .terms-link {
        border-bottom: 1px solid transparent;

        font-weight: 700;

        color: $primary;
        border-bottom: 1px solid $primary;
    }

    .btn-section {
        @include row-align-start-justify-between;
        flex-wrap: wrap;
        width: 100%;

        margin-top: 24px;

        .pay-btn__wrapper {
            width: 100%;
            gap: 10px;
            flex-direction: row !important;
        }

        .checkbox,
        .btn {
            width: 100%;
        }

        @include media($sm) {
            .pay-btn__wrapper {
                @include column-align-end;

                width: unset;
            }

            .checkbox,
            .btn {
                width: unset;
            }
        }
    }
</style>
