<template>
    <div class="montonio">
        <div v-if="loading" class="montonio__loading">
            <Vue3Lottie :animationData="SpinPrimary" class="animation-icon" />
            <div>Prašome palaukti</div>
        </div>
        <div v-else>
            
            <div class="montonio__providers">
                <div class="montonio__provider" :class="{ 'montonio__provider--active' : provider === selectedProvider }" v-for="(index, provider) in data" :key="index" @click="selectProvider(provider)">
                    <div v-if="data[provider].logoUrl">
                        <img :src="data[provider].logoUrl" alt="">
                    </div>
                    <div v-else>
                        Montonio
                    </div>
                </div>
            </div>

            <div class="montonio__content" v-if="selectedProvider === 'paymentInitiation'">
                <div class="montonio__header">
                    <div class="montonio__title">Mokėjimo būdai</div>
                    <div class="montonio__country">
                        <CRSelect
                            class="city-select"
                            :data="countries"
                            :selected="selectedCountry"
                            @select="selectCountry"
                        >
                            <template #icon>
                                <LocationSvg class="icon-location" />
                            </template>
                        </CRSelect>
                    </div>
                </div>
                <div class="montonio__wrap">
                    <div class="montonio__item" :class="{'montonio__item--active' : payment.code == selectedPayment }"  v-for="(payment, index) in paymentMethodsList" @click="selectPayment(payment.code)" :key="index">
                    <img :src="payment.logoUrl" alt="">
                    </div>
                </div>
            </div>
        </div>
        <p>
            {{ $t('paymentMethods.montonioDescription') }}
        </p>
    </div>
</template>
<script>
import CRSelect from '@/components/Common/Select/CRSelect.vue';
import { Vue3Lottie } from 'vue3-lottie';
import { PaymentApi } from '@/api';
import SpinPrimary from '@/assets/animations/spin-primary.json';
import { mapActions } from 'vuex';

export default {
    components: {
        CRSelect,
        Vue3Lottie,
    },
    data() {
        return {
            SpinPrimary,
            loading: false,
            paymentMethods: [],
            countries: [],
            data: {},
            selectedProvider: 'paymentInitiation',
            selectedPayment: null,
            selectedCountry: 'LT',
        }
    },
    computed: {
        paymentMethodsList() {
            if (Object.keys(this.data).length > 0) {
                return this.data[this.selectedProvider].setup[this.selectedCountry].paymentMethods;
            } else {
                return [];
            }
        },
    },
    methods: {
        ...mapActions('checkOut', ['setPaymentData']),
        selectProvider(provider) {
            this.selectedProvider = provider;

            this.setPaymentData({
                provider: this.selectedProvider,
                country: this.selectedCountry,
                payment: this.selectedPayment,
            });
        },
        selectCountry(value) {
            console.log('Country selected');
            this.selectedCountry = value.value;
            this.paymentMethods = this.data[this.selectedProvider].setup[this.selectedCountry].paymentMethods;

            if(this.paymentMethods.length) {
                this.selectedPayment = this.paymentMethods[0].code;

                this.setPaymentData({
                    provider: this.selectedProvider,
                    country: this.selectedCountry,
                    payment: this.selectedPayment,
                });
            }
        },
        selectPayment(payment) {
            this.selectedPayment = payment;
            if(this.selectedProvider === 'paymentInitiation') {
                this.setPaymentData({
                    country: this.selectedCountry,
                    payment: this.selectedPayment,
                    provider: this.selectedProvider,
                });
            }
        },
        async getMethods() {
            this.loading = true;
            this.data = await PaymentApi.montonioPaymentMethods();


            let countryKeys = Object.keys(this.data[this.selectedProvider].setup);
            this.countries = countryKeys.map(key => ({ label: key, value: key }))
            this.paymentMethods = this.data[this.selectedProvider].setup[this.selectedCountry].paymentMethods;

            // select first payment when loading montonio methods
            if(this.paymentMethods.length) {
                this.selectedPayment = this.paymentMethods[0].code;

                this.setPaymentData({
                    country: this.selectedCountry,
                    payment: this.selectedPayment,
                    provider: this.selectedProvider,
                });
            }

            this.loading = false;
        }
    },
    async mounted() {
        await this.getMethods();
    }   
}
</script>
<style lang="scss" scoped>
    .montonio {
        border: solid 1px #eee;
        padding: 10px;
        border-radius: 10px;
        margin-top: 20px;

        &__providers {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        &__provider {
            border: solid 1px transparentize(#828282, .2);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px;
            margin-bottom: 15px;
            border-radius: 10px;
            cursor: pointer;

            &--active {
                background: #E5F6EE;
            }

            img {
                height: 30px;
            }
        }

        p {
            margin-top: 20px;
        }

        &__loading {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                scale: 2;
            }
        }

        &__header {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__wrap {
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
        }

        &__item {
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 10px;
            height: 100px;
            width: 120px;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 10px;

            &:hover {
                background: darken($color: #E5F6EE, $amount: 5);
            }

            &--active {
                background: #E5F6EE;
            }

            img {
                width: 100%;
            }
        }
    }
</style>