<template>
    <BasicModal hide-close-button>
        <div class="container">
            <h2 v-if="title">{{ title }}</h2>
            <Vue3Lottie :animationData="SpinPrimary" class="animation-icon" />
        </div>
    </BasicModal>
</template>

<script>
    import BasicModal from '@/components/Modals/BasicModal.vue';
    import { Vue3Lottie } from 'vue3-lottie';
    import SpinPrimary from '@/assets/animations/spin-primary.json';

    export default {
        name: 'PreloaderModal',
        components: { BasicModal, Vue3Lottie },
        props: {
            title: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                SpinPrimary,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        @include column-align-center;

        width: 100%;

        padding-top: 40px;

        .animation-icon {
            width: 560px;
        }
    }
</style>
